// extracted by mini-css-extract-plugin
export var colClass = "styles-module--colClass--dfe4e";
export var container = "styles-module--container--9b27d";
export var containerSmall = "styles-module--containerSmall--7e1db";
export var imageGrid = "styles-module--imageGrid--6991e";
export var imageGridImage = "styles-module--imageGridImage--bf430";
export var imageGridLogo = "styles-module--imageGridLogo--059aa";
export var img = "styles-module--img--5b524";
export var imgCol = "styles-module--imgCol--efcec";
export var orderFirst = "styles-module--orderFirst--f18ba";
export var orderLast = "styles-module--orderLast--adf1f";
export var subHeadline = "styles-module--subHeadline--b79a2";
export var text = "styles-module--text--930bb";
export var textCol = "styles-module--textCol--1baab";
export var textWrapper = "styles-module--textWrapper--0ed23";